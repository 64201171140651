
.reports-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
}

.reports-item {
    cursor: pointer;
    background-image: url('/icons/report 60px (494847) BG.svg');
    background-repeat: no-repeat;
    background-size: 160px;
    background-position: right -20px bottom -20px;
}

.reports-item:hover {
    box-shadow: 0px 0px 5px rgba(0,0,0,0.125);
}

.reports-item .content-card-footer {
    background: none;
    border-top: none;
}

.reports-name {
    font-size: 16px;
    font-weight: 600;
    color: #494847;
    padding:0 0 12px 0;
}

.reports-description {
    font-size: 12px;
    font-weight: 400;
    color: #6c6c6b;
    min-height: 60px;
}

.reports-item-footer-item {
    display: flex;
    padding: 0 8px 0 0;
}

.reports-item-footer-item_inactive {
    opacity: .25;
}

.reports-item-footer-item_icon {
    width: 17px;
    height: 17px;
    opacity: .4;
    margin: 1px 4px 0 0;
}

.reports-item-footer-item_icon img {
    width: 100%;
    height: 100%;
}

.reports-item-footer-item_text {
    font-size: 14px;
    font-weight: 600;
    color: #494847;
    opacity: .4;
}