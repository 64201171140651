.public__wrapper {
    display: flex;
    background-color: #f5f5f5;
    min-height: 100vh;
    height: 100%;
}

.handbook__categories {
    display: flex;
    flex-direction: column;
    background-color: #353433;
    border-top: 2px solid #2a2a29;
    padding: 16px 0px 0px 0px;
    min-height: 100%;
    min-width: 200px;
}

.handbook__category {
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.75;
    padding: 8px 0 8px 16px;
    cursor: pointer;
    display: flex;
}

.handbook__category:hover {
    opacity: 1;
    background-color: #444342;
}

.handbook__items {
    padding: 32px;
    background-color: #f0f0f0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: start;
}

.handbook__item {
    width: 340px;
    display: flex;
    flex-direction: column;
    height: 140px;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-top: 2px solid #f15b4f;
    border-radius: 0 0 4px 4px;
    padding: 12px 8px 24px;
    margin: 8px;
    cursor: pointer;
}

.handbook__item__title {
    font-size: 16px;
    font-weight: 500;
    color: #494847;
    width: 100%;
    padding: 0 0 12px;
}

.handbook__item__updated {
    font-size: 12px;
    font-weight: 400;
    color: #6c6c6b;
    min-height: 60px;
}
