
/*  https://noeldelgado.github.io/shadowlord/#020100
/*  https://noeldelgado.github.io/shadowlord/#ef4032

/*  APP ------------------------------------------------------------------------------------------------------------- */

.app-container {
  display: grid;
  grid-template-rows: 72px auto;
  height: 100vh;
}

.app-topmenu {
  display: grid;
  grid-template-columns: 240px auto min-content min-content 56px;
  grid-template-areas:  'topmenu-logo topmenu-menu topmenu-UTC topmenu-loggedin topmenu-logout';
  border-bottom: 1px solid #E4E8E7;
  border-top: 4px solid #f15b4f;
}

.app-topmenu-logo {
  grid-area: topmenu-logo;
  padding: 16px 0px 0px 16px;
}

.app-topmenu-logo img {
  height: 32px;
}

.app-topmenu-menu {
  grid-area: topmenu-menu;
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.app-topmenu-menuitem {
  font-size: 14px;
  font-weight: 600;
  color: #717473;
  padding-right: 24px;
  cursor: pointer;
}

.app-topmenu-menuitem-active {
  font-weight: 600;
  color: #c0392b;
}

.app-topmenu-UTC {
  display: grid;
  grid-area: topmenu-UTC;
  grid-template-areas:  "app-topmenu-UTC-icon app-topmenu-UTC-time"
                        "app-topmenu-UTC-icon app-topmenu-UTC-date";
  padding-right: 16px;
  white-space: nowrap;
  border-left: 1px solid #E4E8E7;
  padding-left: 16px;
}

.app-topmenu-UTC-icon {
  grid-area: app-topmenu-UTC-icon;
  width: 32px;
  padding-right: 12px;
  opacity: .5;
}

.app-topmenu-UTC-icon img {
  width: 100%;
  height: 100%;
}

.app-topmenu-UTC-time {
  grid-area: app-topmenu-UTC-time;
  font-size: 14px;
  font-weight: 600;
  color: #494847;
  align-self: flex-end;
}

.app-topmenu-UTC-date {
  grid-area: app-topmenu-UTC-date;
  font-size: 11px;
  font-weight: 300;
  align-self: flex-start;
  line-height: 11px;
  padding-bottom: 4px;
}


.app-topmenu-loggedin {
  display: grid;
  grid-area: topmenu-loggedin;
  grid-template-areas: "app-topmenu-loggedin-icon app-topmenu-loggedin-username"
                        "app-topmenu-loggedin-icon app-topmenu-loggedin-classification";
  padding-right: 16px;
  white-space: nowrap;
  border-left: 1px solid #E4E8E7;
  padding-left: 16px;
}

.app-topmenu-loggedin-icon {
  grid-area: app-topmenu-loggedin-icon;
  width: 32px;
  padding-right: 12px;
  opacity: .5;
}

.app-topmenu-loggedin-icon img {
  width: 100%;
  height: 100%;
}

.app-topmenu-loggedin-username {
  grid-area: app-topmenu-loggedin-username;
  font-size: 14px;
  font-weight: 600;
  color: #494847;
  align-self: flex-end;
}

.app-topmenu-loggedin-classification {
  grid-area: app-topmenu-loggedin-classification;
  font-size: 11px;
  font-weight: 300;
  align-self: flex-start;
  line-height: 11px;
  padding-bottom: 4px;
}

.app-topmenu-logout {
  width: 40px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #c0392b;
  cursor: pointer;
  border-left: 1px solid #E4E8E7;
  padding-left: 12px;
}

.app-topmenu-logout img {
  width: 100%;
  height: 100%;
}

.app-topmenu-logout:hover {
  color: #982d22;
}

.app-content {
  display: grid;
  grid-template-columns: 200px auto;
  align-items: start;
}

.app-sidenav {
  background-color: #353433;
  border-top: 2px solid #2a2a29;
  padding: 16px 0px 0px 0px;
  height: 100%;
}

.app-sidenav-header {
  font-size: 12px;
  font-weight: 700;
  color: #C6CECC;
  padding-bottom: 16px;
}

.app-sidenav-item {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  opacity: 0.75;
  padding: 8px 0 8px 16px;
  cursor: pointer;
  display: flex;
}

.app-sidenav-item:hover {
  opacity: 1;
  background-color: #444342;
}

.app-sidenav-item_icon {
  height: 16px;
  width: 16px;
  margin: 1px 8px 0 0;
  opacity: 0.7;
}

.app-sidenav-item_icon img {
  height: 100%;
  width: 100%;
}

.app-sidenav-group {
  padding: 0 0 8px 0;
}

.app-sidenav-subitem {
  font-size: 13px;
  font-weight: 400;
  color: #908f8f;
  padding: 0 0 2px 24px;
  cursor: pointer;
  display: flex;
}



.app-sidecontent {
  background-color: #f5f5f5;
  height: 100%;
}



/*  CONTENT --------------------------------------------------------------------------------------------------------- */

.content {
  display: grid;
  grid-template-rows: 60px auto;
  align-content: baseline;
  height: 100%;
}

.content-topmenu {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #E4E8E7;
  border-top: 2px solid #F4F6F5;
  font-size: 17px;
  font-weight: 600;
  color: #4B4F4E;
}

.content-topmenu_icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.content-topmenu_icon img {
  width: 100%;
  height: 100%;
}

.content-topmenu_submenu {
  display: flex;
  color: #a2a2a1;
  font-weight: 400;
  font-size: 14px;
  height: 100%;
}

.content-topmenu_submenu-item {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.content-topmenu_submenu-item:hover {
  color: #c4473a;
  font-weight: 500;
}

.content-topmenu_submenu-itemtext {
  padding: 0 12px 0 12px;
}

.content-topmenu_submenu-item-selected {
  color: #c4473a;
  font-weight: 500;
  border-bottom: 2px solid #d27066;
}

.content-wrapper {
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  align-items: start;
}

.content-card {
  width: 100%;
  display: grid;
  grid-template-rows: 60px auto;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.content-card-hide {
    display: none;
}

.content-card-noheader {
  width: 100%;
  display: grid;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-top: 2px solid #f15b4f;
  border-radius: 0 0 4px 4px;
}

.content-card-header_title {
  padding-right: 48px;
}

.content-card-header {
  font-size: 18px;
  font-weight: 600;
  color: #4B4F4E;
  letter-spacing: -.5px;
  align-items: center;
  padding: 0 8px 0 24px;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}

.content-card-header_options {
  display: flex;
  margin-left: auto;
}

.content-card-header_options .ui.button {
  color: #a2a2a1;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  font-weight: 700;
}

.content-card-header_options .ui.button:hover {
  background-color: #fafaf9;
}

.content-card-subheader {
  font-size: 16px;
  font-weight: 700;
  color: #717473;
  letter-spacing: -.5px;
  padding-bottom: 16px;
}

.content-card-content {
  padding: 16px 8px;
  display: grid;
}

.content-card-content-options {
  padding: 8px 0 24px 12px;
  display: flex;
  flex-direction: row;
}

.content-card-content-options_data {
  margin-left: auto;
}

.content-card-content-options .ui.button {
  color: #a2a2a1;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  font-weight: 700;
}

.content-card-content-options .ui.button:hover {
  background-color: #fafaf9;
}

.content-card-footer {
  border-top: 1px solid #f0f0f0;
  height: 48px;
  background-color: #F8F9F9;
  display: flex;
  align-items: center;
  border-radius: 0 0 4px 4px;
  padding: 0 10px 0 10px;
}

.content-card-footer-lastmodified {
  opacity: 0.75;
  display: grid;
  grid-template-columns: 22px auto;
  padding-left: 8px;
  align-items: center;
}

.content-card-footer-lastmodified_text {
  font-size: 11px;
  font-weight: 600;
  color: #676C6B;
  line-height: 11px;
}

.content-card-footer-lastmodified_icon {
  width: 19px;
  height: 19px;
  float: left;
  cursor: pointer;
  opacity: .8;
}

.content-card-footer-lastmodified_icon img {
  height: 100%;
  width: 100%;
}


.content-card-footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.content-card-footer-buttons .ui.button {
  background-color: #ffffff;
  border: 1px solid #E8E9E9;
  font-size: 11px;
  font-weight: 600;
  color: #676C6B;
  padding: 10px 16px 10px 16px;
}


/*  tabs -------------------------------------------------------------------------------------------------------------*/

.content-tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: -25px;
  padding: 0 8px;
}

.content-tabs-item {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
  cursor: pointer;
}

.content-tabs-item-active {
  z-index: 1;
}


/*  modals -----------------------------------------------------------------------------------------------------------*/


.ui.modal {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-top: 2px solid #f15b4f;
  border-radius: 0 0 4px 4px;
}

.modal--header {
  border-radius: 0;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #494847;
  padding: 16px 8px;
  align-items: center;
}

.modal--content {
  padding: 12px 12px 32px 12px;
}

.modal--content-instructions {
  font-style: italic;
  font-size: 14px;
  font-weight: 300;
  color: #959594;
  line-height: 16px;
  padding: 0 0 24px 0;
}

.modal--tag-panel {
  border: 1px solid #ebebeb;
  background-color: #fbfbfb;
  padding: 8px 8px 4px 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 48px;
}

.modal--footer {
  border-top: 1px solid #ebebeb;
  height: 56px;
  background-color: #fafafa;
  display: grid;
  align-items: center;

}

.modal--footer-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}


/*  alerts -----------------------------------------------------------------------------------------------------------*/

.alert {
  font-size: 13px;
  line-height: 14px;
  font-weight: 500;
  display: flex;
  margin: 8px 0;
  border-radius: 0 4px 4px 0;
}

.alert_icon {
  align-items: stretch;
  display: flex;
}

.alert_icon_inner {
  display: flex;
  align-items: center;
  padding: 0 6px 0 4px;
}

.alert img {
  width: 16px;
  height: 16px;
}

.alert_text {
  padding: 12px 8px 12px 8px;
  font-weight: 500;
}

.alert_text span {
  font-weight: 700;
}

.alert.success {
  background-color: #f3f9f0;
  border-left: 3px solid #5dae32;
}

.alert.success .alert_icon {
  background-color: #e3f1dc;
}

.alert.success .alert_text {
  color: #51a823;
}

.alert.success .alert_text span {
  color: #4b9c21;
}

.alert.warning {
  background-color: #fbf1f0;
  border-left: 3px solid #cd6358;
}

.alert.warning .alert_icon {
  background-color: #f5dfdd;
}

.alert.warning .alert_text {
  color: #c85144;
}

.alert.warning .alert_text span {
  color: #C0392B;
}

.alert.info {
  background-color: #eff3fe;
  border-left: 3px solid #497cf5;
}

.alert.info .alert_icon {
  background-color: #dae4fd;
}

.alert.info .alert_text {
  color: #346df4;
}

.alert.info .alert_text span {
  color: #1859F2;
}

.alert.system {
  background-color: #fef9ed;
  border-left: 3px solid #F4A700;
}

.alert.system .alert_icon {
  background-color: #fdf1d6;
}

.alert.system .alert_text {
  color: #F4A700;
}

.alert.system .alert_text span {
  color: #F4A700;
}

/*  tags -------------------------------------------------------------------------------------------------------------*/

.tag {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  padding: 8px 12px;
  flex-shrink: 2;
  margin: 0 4px 4px 0;
  font-size: 12px;
  font-weight: 500;
  color: #494847;
  cursor: pointer;
}

.tag:hover {
  color: #f15b4f;
}

.tag.active {
  color: #ffffff;
  background-color: #f15b4f;
  border-color: #f15b4f;
  font-weight: 600;
}

.tag.active:hover {
  background-color: #f3756b;
  border-color: #f3756b;
}


  /*  popup admin ------------------------------------------------------------------------------------------------------*/

.pop_admin-item {
  font-weight: 500;
  color: #6f6e6e;
  font-size: 13px;
  /*border-top: 1px solid #ebebeb;*/
  padding: 12px 48px 12px 24px;
  display: flex;
  cursor: pointer;
}

.pop_admin-item_icon {
  width: 17px;
  height: 17px;
  margin-top: 1px;
  margin-right: 16px;
}

.pop_admin-item_icon img {
  width: 100%;
  height: 100%;
}

.pop_admin-item:hover {
  background-color: #f8f8f8;
  color: #494847;
}

.pop_admin-item:first-of-type {
  border: none;
}

/*  SEMANTIC UI COMPONENTS ===========================================================================================*/

/*  form elements ----------------------------------------------------------------------------------------------------*/

.ui.form .fields {
  margin: 0;
}

.ui.form .fields>.field {
  padding: 0 0 16px 0 !important;
}

.ui.form .field>label {
  font-size: 13px !important;
  margin: 0px 0px 4px 0px !important;
  color: #bcbbbb !important;
  font-weight: 500 !important;
}

.ui.form .field.field input,
.ui.form .field.field textarea {
  font-family: 'Roboto', sans-serif !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #494847 !important;
  border-color: #ebebeb !important;
  box-shadow: 0 0 0 100px #fbfbfb inset!important;
  border-radius: 2px !important;
}

.ui.form .field.field input:-webkit-autofill::first-line {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #494847 !important;
}

.ui.form .field.field input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill:hover,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form .field.field textarea:-webkit-autofill,
.ui.form .field.field textarea:-webkit-autofill:hover,
.ui.form .field.field textarea:-webkit-autofill:focus,
.ui.form .field.field select:-webkit-autofill,
.ui.form .field.field select:-webkit-autofill:hover,
.ui.form .field.field select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 100px #fbfbfb inset !important;
  -webkit-text-fill-color: #494847 !important;
  border-color: #ebebeb !important;
}

.ui.dropdown {
  min-height: unset;
  padding: 7px 30px 8px 13px;
  font-weight: 400 !important;
  color: #4B4F4E !important;
  border-color: #ebebeb !important;
  border-radius: 2px !important;
  box-shadow: inset 0 0 0 100px #fbfbfb!important;
}

.ui.dropdown>.text {
  padding-top: 3px;
  font-size: 13px!important;
  font-weight: 600!important;
  color: #494847 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.ui.dropdown>.default.text {
  font-weight: 500 !important;
  color: #a2a2a1 !important;
}

.ui.dropdown .menu {
  border-color: #ebebeb !important;
}

.ui.dropdown .menu>.item {
  font-weight: 400 !important;
  color: #676C6B !important;
  font-size: 13px !important;
}

.ui.dropdown .menu .selected.item {
  font-weight: 600 !important;
}


/*  buttons ----------------------------------------------------------------------------------------------------------*/

.ui.button {
  font-family: 'Roboto', sans-serif !important;
  border-radius: 2px !important;
  font-size: 13px;
  line-height: 14px;
  font-weight: 900;
}

.ui.button.btn_icononly .btn_icon {
  margin-right: -8px;
}


.ui.button .btn_icon {
  width: 17px;
  height: 17px;
  margin: -4px 10px -4px -6px;
}

.ui.button.btn_primary {
  background-color: #f15b4f;
  color: #ffffff;
  border: 1px solid #f15b4f;
  opacity: 0.5;
  cursor: default;
}

.ui.button.btn_secondary {
  background-color: #ffffff;
  color: #f15b4f;
  font-weight: 700;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.ui.button.btn_tertiary {
  color: #f15b4f;
  background-color: transparent !important;
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
}

.ui.button.btn_primary.btn_active {
  opacity: 1;
  cursor: pointer;
}

.ui.button.btn_secondary.btn_active {
  opacity: 1;
  cursor: pointer;
}

.ui.button.btn_primary.btn_active:hover {
  background-color: #ef4032;
  border-color: #ef4032;
}

.ui.button.btn_secondary:hover {
  color: #ef4032;
}

.ui.button.btn_tertiary:hover {
  color: #ef4032;
}

.ui.button.loading.btn_primary {
  background-color: #f15b4f;
}

.ui.button.btn_secondary.btn_disabled {
  background-color: #E4E5E4 !important;
  border-color: #B1B3B2 !important;
  color: #717473 !important;
  cursor: default !important;
}

/*  tables -----------------------------------------------------------------------------------------------------------*/

.ui.table {
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 500;
  color: #494847;
}

.ui.table thead th {
  background: none;
  font-size: 11px;
  font-weight: 600;
  color: #c7c7c7;
  padding-top: 16px;
  padding-bottom: 8px;
  cursor: pointer;
}

.ui.table thead tr:hover {
  background: none;
}

.ui.table tr {
  cursor: pointer;
}

.ui.table tr:hover {
  background-color: rgba(0,0,0,.025);
}

.ui.compact.table td {
  padding: 6px 8px !important;
}

.tbl_loading {
  opacity: .5;
}

.tbl_cell_dashed {
  border-left: 1px dashed #f2f2f2;
}

.tbl_cell_statusicon {
  display: flex;
  justify-content: center;
  align-content: center;
}

.tbl_cell_statusicon img {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}


/*  popup -----------------------------------------------------------------------------------------------------------*/

.ui.popup {
  padding: 8px 0 8px 0 !important;
  border-color: #ebebeb !important;
}

.ui.popup:before {
  box-shadow: -1px -1px 0 0 #e4e4e3 !important;
}



/*  unused -----------------------------------------------------------------------------------------------------------*/



.ui.radio.checkbox {
  padding-right: 5px !important;
}


.ui.radio.checkbox label {
  padding-left: 20px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #676C6B !important;
}


.ui.selection.dropdown>.dropdown.icon {
  top: unset;
}

.ui.search.dropdown>input.search {
  left: 0 !important;
}



.ui.progress {
  background: #ffffff !important;
  border: solid 1px #BBBFBE !important;
  border-radius: 4px !important;
}

.ui.progress .bar {
  background: #3DBF1C !important;
  border-radius: 3px !important;
}



@media only screen and (max-width: 1000px) {

  .app-container {
    grid-template-rows: 100px auto;
  }

  /*test comment*/

  .app-topmenu {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:  'topmenu-logo topmenu-loggedin'
                          'topmenu-menu topmenu-menu';
  }

  .app-topmenu-menu {
    justify-content: center;
  }

  .app-content {
    grid-template-columns: 1fr;
    grid-template-rows: 40px auto;
  }

  .app-sidenav {
    padding: 0;
    border-right: none;
    border-bottom: 1px solid #E4E8E7;
  }

  .app-sidenav-group {
    display: flex;
    justify-content: center;
  }

  .app-sidenav-header {
    display: none;
  }

  .app-sidenav-item {
    padding: 8px;
  }

  .content-wrapper {
    padding: 12px
  }

}

@media only screen and (max-width: 650px) {

  #app-topmenu-menuitem_organization {
    display: none;
  }

  #app-sidenav-group_organization {
    display: none;
  }
}

@media only screen and (min-width: 616px) {

  .ui.selection.dropdown {
    height: 37px !important;
    padding: 8px 30px 8px 13px !important;
    font-size: 14px !important;
  }

}

@media only screen and (max-width: 615px) {

  .ui.input>input,
  .ui.form .field.field input,
  .ui.selection.dropdown,
  .ui.dropdown .menu>.item {
    font-size: 16px !important;
  }

  .ui.selection.dropdown {
    height: 40px !important;
    padding: 9px 30px 8px 13px !important;
    font-size: 16px !important;
  }

  .ui.form .field.field input {
    background-color: rgba(250,250,250,0) !important;
  }

}


@media only screen and (max-width: 500px) {

  .content-wrapper {
    padding: 6px
  }

}
