
.login_wrapper {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
}

.login {
    width: 300px;
    border-radius: 3px 3px 5px 5px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.125);
    background-image: url('/icons/globe 60px (494847) BG.svg');
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: right -75% bottom -50%;
}

.login_header {
    height: 70px;
    text-align: center;
    background-color: #f15b4f;
    border-radius: 3px 3px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid #ffffff;
}

.login_header img{
    height: 56%;
    /*opacity: .95;*/
}

.login_content {
    padding: 28px;
    border-top: 4px solid #3f3e3d;
}

.testing {
    width: 100%;
}

.email {
    width: 100%;
}

.password {
    width: 100%;
}

.login_buttons {
    display: flex;
    padding: 16px 0 0 0;
}

.alert_newpassword,
.alert_forgotpassword,
.alert_validatepasswordreset {
    margin-bottom: 28px !important;
}

.newpassword {
    width: 100%;
}


.passwordvalidation {
    color: #afaeae;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 16px;
}

.passwordvalidation_item {
    display: flex;
    padding-bottom: 2px;
}

.passwordvalidation_item_icon {
    width: 16px;
    margin-right: 6px;
    margin-top: -3px;
}

.passwordvalidation_item_icon img {
    width: 100%;
    height: 100%;
}

.passwordvalidation_item_icon_fail {
    opacity: 0.5;
}

