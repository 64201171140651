
.cc_covid19_wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}



/*  MODAL: ADMIN DISTRIBUTION ----------------------------------------------------------------------------------------*/

#mdl_admindistribution {
    width: 700px;
}

.mdl_admindistribution_classificationswrapper {
    display: grid;
    grid-template-rows: minmax(0, auto) 1fr;
    grid-row-gap: 8px;
    padding: 0 8px;
}

.mdl_admindistribution_classificationsassigned {
    border: 1px solid #ebebeb;
    background-color: #fbfbfb;
    padding: 8px 8px 4px 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 48px;
}

.mdl_admindistribution_classifications_title {
    font-size: 15px;
    font-weight: 600;
    color: #de3c2f;
    padding: 16px 0 8px 0;
}

.mdl_admindistribution_classificationsunassigned {
    border: 1px solid #ebebeb;
    background-color: #fbfbfb;
    padding: 8px 8px 4px 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    min-height: 48px;
}

.mdl_admindistribution_classificationsunassigned .tag {
    color: #898887;
}

