
.approvals-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
}

.approvals-item {
    cursor: pointer;
    background-image: url('/icons/checkmark 60px (494847) BG.svg');
    background-repeat: no-repeat;
    background-size: 160px;
    background-position: right -20px bottom -20px;
}

.approvals-item:hover {
    box-shadow: 0px 0px 5px rgba(0,0,0,0.125);
}

.approvals-item .content-card-footer {
    background: none;
    border-top: none;
}

